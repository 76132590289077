<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 1rem;">角色名称</span>
					<el-input v-model="searchinput" placeholder="请输入角色名称"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="info" @click="resetsearch">重置</el-button>
					<el-button type="success" @click="addnew=true">新增</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
					<!-- <div class="mybtn" style="margin-right: 0.3rem;" @click="addnew=true">新增</div>
					<div class="mybtn">导出</div> -->
				</div>
				<div style="height: 73vh;padding-top: 10px;box-sizing: border-box;">
					<MyTable :tableData="tableData" :tableHeader="tableHeader" :showbm="true" :czwidth="250" :showpages="false">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showedit(scope.row)" type="warning" size="small"
								style="margin-right: 0.1rem;">修改</el-button>
							<el-button @click="fenpei(scope.row)" type="primary" size="small">分配权限</el-button>
							<el-button @click="deleterow(scope.row)" type="danger" size="small">删除</el-button>

						</template>
					</MyTable>
				</div>

			</div>
		</div>


		<!--新增-->
		<el-dialog :title="isedite?'修改角色':'新增角色'" :visible.sync="addnew" width="30%" :before-close="closeadd">
			<el-form :model="addnewinfo" :roles="addnewinfo_roles" ref="addnewinfo" label-width="140px"
				class="demo-ruleForm">
				<el-form-item label="角色名称" prop="title">
					<el-input v-model="addnewinfo.title" style="width: 80%;"></el-input>
				</el-form-item>
				<!-- <el-form-item label="是否为驿站管理员" class="centerbox">
					<el-switch
					  v-model="addnewinfo.is_yun_manage"
					  :active-value="1"
					  :inactive-value="0">
					</el-switch>
				</el-form-item> -->
				<el-form-item label="拥有权限" prop="rolespc">
					<div style="height: 40vh;overflow-y: auto;">
						<el-tree
						   ref="tree"
						  :data="roterdata"
						  show-checkbox
						  node-key="id"
						  :current-node-key="addnewinfo.rolespc"
						  :props="defaultProps">
						</el-tree>
					</div>
					
				</el-form-item>				
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeadd">取 消</el-button>
				<el-button type="primary" @click="setaddnew">确 定</el-button>
			</span>
		</el-dialog>
		
		
		<!--分配权限-->
		<el-dialog title="分配权限" :visible.sync="fenpeiflag" width="40%">
			<el-form ref="addnewinfo" label-width="140px"
				class="demo-ruleForm">
				<el-form-item label="角色名称" required>
					<div>{{rowde.title}}</div>
				</el-form-item>
				<el-form-item label="分配人员" class="centerbox" required>
					<el-button @click="selectdata" type="primary" size="small">选择人员</el-button>
				</el-form-item>		
				<el-form-item label="已选人员">
					<div>
						{{user_name}}
					</div>
				</el-form-item>	
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="fenpeiflag = false">取 消</el-button>
				<el-button type="primary" @click="fenpsure">确 定</el-button>
			</span>
		</el-dialog>
		
		<selectusers ref="myselectuser" @selectedry="selectedry"></selectusers>
	</div>
</template>

<script>
	import selectusers from '@/components/select_users.vue'
	export default {
		components: {
			selectusers
		},
		data() {
			return {
				roterdata:'',
				defaultProps: {
				  children: 'chlidren',
				  label: 'title'
				},
				searchinput: '',
				tableHeader: [{
						name: '序号',
						prop: 'id',
						width: '80'
					},
					{
						name: '角色名称',
						prop: 'title',
						width: ''
					}
				],
				tableData: [],
				addnew: false,
				isedite: false,
				addnewinfo: {
					title: '',
					roles:'',
					rolespc:'',
					// is_yun_manage:1,
					status:1
				},
				addnewinfo_roles: {
					title: [{
						required: true,
						message: '请输入角色名称',
						trigger: 'blur'
					}]
				},
				fenpeiflag:false,
				user_id: '',
				user_name: '',
				rowde:''
			}
		},
		created() {
			
			this.$post({
				url:'/api/role/index',
				params:{
					page:1,
					page_size:9999
				}
			}).then((res)=>{
				this.roterdata = res.list
			})
		},
		mounted() {
			this.gettable()
		},
		methods: {
			fenpsure(){
				if(!this.user_id){
					this.$message.warning('请选择人员')
					return
				}
				this.$post({
					url:'/api/user/plxgqx',
					params:{
						user_ids:this.user_id,
						id:this.rowde.id
					}
				}).then((res)=>{
					this.$message.success('操作成功')
					this.fenpeiflag = false
					this.user_id = ''
					this.user_name = ''
					this.rowde =''
				})
				
			},
			selectedry(e){
				this.user_id = e.map((item)=>{return item.id}).join(',')
				this.user_name = e.map((item)=>{return item.name}).join(',')
			},
			selectdata(){
				let cxidlist = this.user_id?this.user_id.split(','):[]
				let cxnamelist = this.user_name?this.user_name.split(','):[]				
				let list = []
				if(cxidlist.length>0){
					cxidlist.forEach((item,index)=>{
						let obj={
							name:cxnamelist[index],
							id:Number(item)
						}
						list.push(obj)
					})
				}				
				this.$refs.myselectuser.selectuser = list.length>0?list:[]
				this.$refs.myselectuser.dialogVisible = true
			},
			fenpei(row){
				this.rowde = row
				this.fenpeiflag = true
			},
			resetsearch(){
				this.searchinput=''
				this.gettable()
			},
			search(){
				this.gettable()
			},
			gettable(){
				this.$post({
					url:'/api/roleadmin/index',
					params:{
						page:1,
						page_size:9999,
						username:this.searchinput
					}
				}).then((res)=>{
					console.log(res)
					this.tableData = res.list
				})
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url:'/api/Roleadmin/del',
							params:{
								ids:row.id
							}
						}).then((res)=>{
							this.$message.success('删除成功');
							this.gettable()
							
						})
					})
					.catch(_ => {});
				
			},
			showsetrole(row) {
				console.log(row)
			},
			changswitch(row) {
				console.log(row)
			},
			showedit(row) {
				this.addnewinfo={
					id:row.id,
					title: row.title,
					roles:row.roles,
					rolespc:row.rolespc,
					describe:row.describe,
					// is_yun_manage:row.is_yun_manage,
					status:1
				}
				this.isedite = true
				this.addnew = true
				let arry = row.rolespc.split(',')
				setTimeout(()=>{
					this.$refs.tree.setCheckedKeys(arry)
				},200)
				
			},
			closeadd(){
				this.addnewinfo={
					title: '',
					roles:'',
					rolespc:"",
					describe: '',
					status:1
				}
				this.addnew = false
				this.isedite = false
			},
			setaddnew() {
				this.addnewinfo.roles = this.$refs.tree.getCheckedKeys().join(",")
				let father = this.$refs.tree.getHalfCheckedKeys().join(',')
				father = father?','+father:""
				this.addnewinfo.rolespc = this.addnewinfo.roles
				this.addnewinfo.roles = this.addnewinfo.roles + father				
				this.$post({
					url:this.isedite?'/api/roleadmin/edit':'/api/roleadmin/add',
					params:this.addnewinfo
				}).then((res)=>{
					this.$message.success('添加成功');
					this.closeadd()
					this.gettable()					
				})
				
			}
		},
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}
	::v-deep .el-table__fixed-right::before{
		background-color: #fff !important;
	}
	::v-deep .centerbox{
		display: flex;
		align-items: center;
	}
	::v-deep .has-gutter .el-checkbox{
		display: block !important;
	}
</style>